import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { directus } from '../api';
import { AuthContextType } from '../types/AuthContextType';
import { Storage } from '../components';
import { readItem, readMe } from '@directus/sdk';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const storage = new Storage('directus-data');
  const [token, setToken] = useState<JSON>(storage.get()|| {});
  const navigate = useNavigate();

  const loginAction = async (data: { email: string; password: string }) => {
    try {
      const response = await directus.login(data.email, data.password, {mode: 'json'});
      if (response.access_token && response.refresh_token && response.expires_at) {
        storage.set({
          access_token: response.access_token,
          refresh_token: response.refresh_token,
          expires_at: response.expires_at
        });
        setToken(storage.get());
        navigate('/');
        return;
      }
      throw new Error('Login failed');
    } catch (err) {
      // configuration of the system error to move to notification once it's build
      console.error(err);
    }
  };

  const logOut = () => {
    // logout via directus configuration
    directus.logout();

    // clean up token in localStorage
    storage.set({})
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  
  return context;
};

export default AuthProvider;