/* eslint-disable */
class Storage {
    key: string;
    constructor(key: string) {
        this.key = key;
    }
    get() {
        return JSON.parse(localStorage.getItem(this.key) || "{}");
    }
    set(data: any) {
        localStorage.setItem("directus-data", JSON.stringify(data));
    }
};

export default Storage;
  