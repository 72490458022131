import { Routes, Route } from "react-router-dom";
import { MainPage, Login } from './pages';
import { PrivateRoute } from './routes';
import { AuthProvider } from './context';
import './App.css';

// router configuration
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
              <Route path="/" element={<MainPage />} />
              {/* catch all information to back to home */}
              <Route path="/*" element={<MainPage />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}
export default App;
