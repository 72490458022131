import { createDirectus, authentication, rest } from '@directus/sdk';
import { config } from '../config';
import { Storage } from '../components';

const directus = createDirectus(config.DIRECTUS_BACKEND_API).with(
    authentication("json", {credentials: "include", autoRefresh: true, storage: new Storage('directus-data')})
).with(
    rest()
);

export default directus;